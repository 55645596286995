<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">부가세정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>세금유형</th>
            <td>
              <!-- <comm-opt
                :classList="['type_medium']"
                :optionDesc="'세금유형 선택상자'"
                :optionName="taxCodeName"
                :optionList="taxCodeList"
                :eventName="'select'"
              /> -->
              {{ surtax.surtaxName }}
            </td>
            <th>처리유형</th>
            <td>
              <!-- <div class="row_pack"> 
                <Radio
                  :dataList="receiptBillList"
                  name="receiptBill"
                  :selectedId.sync="surtax.receiptBill"
                />
                <div class="area_right">
                  <Input
                    style="width:88px;"
                    :value.sync="surtax.exclusionOfDeductionReason"
                    :maxLength="100"
                    @focusout="onFocusout"/>
                  <span class="txt_view_r">건</span>
                </div> 
             </div> -->
              {{ receiptBillName }}
            </td>
          </tr>
          <tr>
            <th>발행유형</th>
            <td>
              <!-- <Radio
                :dataList="electroWriteList"
                name="electroWrite"
                :selectedId.sync="surtax.electroWrite"
              /> -->
              {{ electroWriteName }}
            </td>
            <th>자산구분</th>
            <td>
              <!-- <comm-opt
                :classList="['type_medium']"
                :optionDesc="'세금유형 선택상자'"
                :optionName="vatAssetTypeName"
                :optionList="vatAssetTypeList"
                :eventName="'select'"
              /> -->
              {{ vatAssetTypeName }}
            </td>
          </tr>
          <tr>
            <th>불공제 사유</th>
            <td colspan="3">
              <!-- <Input
                :value.sync="surtax.exclusionOfDeductionReason"
                :maxLength="100"
                @focusout="onFocusout"/> -->
              {{ surtax.exclusionOfDeductionReason }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CommOpt from "@/_approval/components/common/CommOpt";
import Radio from "@/_approval/components/common/radio/Radio";
import Input from "@/_approval/components/common/input/Input";

export default {
  name: "StatementSurtax",
  components: {
    CommOpt,
    Radio,
    Input,
  },
  props: {
    surtax: Object,
  },
  data() {
    return {
      taxCodeList: [],
      receiptBillList: [
        { code: "R", name: "영수" },
        { code: "D", name: "청구" },
      ],
      electroWriteList: [],
      vatAssetTypeList: [
        { code: "", name: "없음" },
        { code: "BUILDING", name: "건물구축물" },
        { code: "MACHINE", name: "기계장치" },
        { code: "CAR", name: "차량운반구" },
        { code: "ETC", name: "그 밖의 감가상각자산" },
      ],
    };
  },
  computed: {
    taxCodeName() {
      return this.taxCodeList.find((item) => item.code == this.surtax.surtaxCode)[0].name;
    },
    vatAssetTypeName() {
      //return this.vatAssetTypeList.find(item => item.code == this.surtax.vatAssetType)[0].name;
      if (this.vatAssetTypeList.length == 0) return "";

      const code = this.vatAssetTypeList.find((item) => item.code == this.surtax.vatAssetType);
      if (!code) return this.vatAssetTypeList[0].name;

      return code.name;
    },
    receiptBillName() {
      if (!this.surtax.receiptBill) return "";

      const receiptBillDesc = this.getReceiptBillDesc(this.surtax.receiptBill);
      const numberOfVat =
        !this.surtax.numberOfVat || this.surtax.numberOfVat === "0" ? 0 : this.surtax.numberOfVat;
      return receiptBillDesc + "  " + numberOfVat + "건";
    },
    electroWriteName() {
      const receiptBillDesc = this.getElectroWriteDesc(this.surtax.electroWrite);
      return receiptBillDesc;
    },
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    getReceiptBillDesc(code) {
      switch (code) {
        case "R":
          return "영수";
        case "D":
          return "청구";
        default:
          return "";
      }
    },
    getElectroWriteDesc(code) {
      switch (code) {
        case "ELECT":
          return "전자";
        case "WRITE":
          return "수기";
        default:
          return "";
      }
    },
  },
};
</script>
