<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">기간인식</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>기간</th>
            <td>{{ voucher.periodStartDate }} ~ {{ voucher.periodEndDate }}</td>
            <th>구분</th>
            <td>{{ prospectiveRetroactive }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_comm tbl_list tbl_list_sm12">
      <table>
        <thead>
          <tr>
            <th>손익계정과목</th>
            <th>세목</th>
            <th>손익부서</th>
            <th>손익프로젝트코드</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ voucher.incomeLossAccountName }}</td>
            <td>{{ voucher.incomeLossTaxName }}</td>
            <td>{{ voucher.incomeLossDeptName }}</td>
            <td>{{ voucher.incomeLossServiceName }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatementPeriod",
  props: {
    voucher: Object,
  },
  computed: {
    prospectiveRetroactive() {
      if (this.voucher.prospectiveRetroactive === "F") return "전진";
      else if (this.voucher.prospectiveRetroactive === "B") return "소급";
      return "";
    },
  },
};
</script>
