<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">원천세</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>사업장</th>
            <td colspan="3">
              {{ withholdingTax.businessPlacesName }}
            </td>
          </tr>
          <tr>
            <th>세금유형</th>
            <td>{{ withholdingTax.withholdingTaxName }}</td>
            <th>하위분류</th>
            <td>{{ withholdingTax.subdivisionName }}</td>
          </tr>
          <tr>
            <th>세액회사 부담</th>
            <td>{{ withholdingTax.taxBurdenYn == "Y" ? "예" : "아니오" }}</td>
            <th>이메일</th>
            <td>{{ withholdingTax.email.getTextFull() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "StatementHoldingTax",
  props: {
    withholdingTax: Object,
  },
  data() {
    return {
      taxCodeList: [
        // 임시 데이터
        // { code:'A', name:'세금유형1' },
        // { code:'B', name:'세금유형2' },
        // { code:'C', name:'세금유형3' },
        // { code:'D', name:'세금유형4' },
        // { code:'E', name:'세금유형5' },
        // { code:'F', name:'세금유형6' },
        // { code:'G', name:'세금유형7' },
        // { code:'H', name:'세금유형8' },
        // { code:'I', name:'세금유형9' },
        // { code:'J', name:'세금유형10' },
        // { code:'K', name:'세금유형11' },
        // { code:'L', name:'세금유형12' },
        // { code:'M', name:'세금유형13' },
      ],
      subdivisionCodeList: [
        // { code:'A', name:'하위분류1' },
        // { code:'B', name:'하위분류2' },
        // { code:'C', name:'하위분류3' },
        // { code:'D', name:'하위분류4' },
        // { code:'E', name:'하위분류5' },
        // { code:'F', name:'하위분류6' },
        // { code:'G', name:'하위분류7' },
        // { code:'H', name:'하위분류8' },
        // { code:'I', name:'하위분류9' },
        // { code:'J', name:'하위분류10' },
        // { code:'K', name:'하위분류11' },
        // { code:'L', name:'하위분류12' },
        // { code:'M', name:'하위분류13' },
      ],
    };
  },
  computed: {
    taxCodeName() {
      return this.taxCodeList.find((item) => item.code == this.withholdingTax.taxCode).name;
    },
    subdivisionCodeName() {
      return this.subdivisionCodeList.find(
        (item) => item.code == this.withholdingTax.subdivisionCode,
      ).name;
    },
  },
  // mounted() {
  //   this.getWithHolding();
  // },
  methods: {
    // async getWithHolding(){
    //   const path = `${ this.$apiPath.VOUCHER_WITHHOLDING }`;
    //   const res = await ApiService.shared.getData(path);
    //   if(res.data) {
    //     this.taxCodeList = res.data;
    //   }
    // }
  },
};
</script>
