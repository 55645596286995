<template>
  <li :class="{ open: !isFold }">
    <div class="head_fold">
      <!-- 우측 영역 -->
      <div class="area_right">
        <dl class="list_brief">
          <dt class="screen_out">증빙유형</dt>
          <dd>{{ certificateItemData.proofName }}</dd>
          <dt>총금액</dt>
          <dd>
            <span class="txt_price">{{ certificateItemData.payment.totalAmount | currency }}</span>
            {{ certificateItemData.payment.currency }}
          </dd>
        </dl>
        <button class="btn_fold" @click="onClickFold">
          <span class="ico_account ico_arr_ellip">{{ isFold ? "펼치기" : "접기" }}</span>
        </button>
      </div>

      <!-- 제목 영역 -->
      <div class="fold_subject">
        <span class="txt_subject">{{ certificateItemData.certificateDesc }}</span>
      </div>
    </div>
    <div v-if="!isFold" class="body_fold">
      <!-- 결제정보 -->
      <StatementPayment :certificateItemData="certificateItemData" />
      <!-- 지급처정보 -->
      <StatementPartner :docflag="docflag" :certificateItemData="certificateItemData" />
      <!-- 계약/발주/검수 정보 -->
      <StatmentOtherMenuInfo
        :certificateItemData="certificateItemData"
        :inspectHistory="inspectHistory"
        @onClickPopInspect="onClickPopInspect"
      />
      <!-- 지출정산서 전표정보 -->
      <StatementVoucher
        v-if="!isInfraStatement"
        :docflag="docflag"
        :apprType="apprType"
        :voucher="certificateItemData.voucher"
        :isAddApprover="isAddApprover"
        :isCompleteStep="isCompleteStep"
        :assetOpenIndex.sync="assetOpenIndex"
        @onUpdateAssetOpen="onUpdateAssetOpen"
      />
      <!-- 인프라정산서 전표정보 -->
      <InfraVoucher
        v-else
        :docflag="docflag"
        :apprType="apprType"
        :companyName="certificateItemData.partnerName"
        :voucher="certificateItemData.voucher"
        :isAddApprover="isAddApprover"
        :isCompleteStep="isCompleteStep"
      />
      <!-- 자산 -->
      <StatementAsset v-if="hasAsset" :assetVoucher.sync="selectedAssetAccountItem" />
      <!-- 기간인식 -->
      <!-- v-if="voucher > voucherItems 중 계정과목이 선급비용(기간인식)인 항목이 하나라도 있으면 노출" : 상세화면설계서 v1.0 기준 59Page 참고하여 진행 -->
      <!-- && apprType == 'F' -->
      <StatementPeriod v-if="hasPeriod" :voucher="certificateItemData.voucher" />
      <!-- 원천세 -->
      <StatementHoldingTax
        v-if="certificateItemData.proofType == 'WT'"
        :withholdingTax="certificateItemData.voucher.withholdingTax"
      />
      <!-- 부가세 -->
      <StatementSurtax
        v-if="docflag == 'AC' && certificateItemData.proofType != 'WT'"
        :surtax="certificateItemData.voucher.surtax"
      />
      <!-- <StatementSurtax
        v-if="apprType=='F'"
        :surtax="certificateItemData.voucher.surtax"/> -->
    </div>
    <PopInspect
      v-if="isPopInspect"
      :inspectData="popInspectData"
      :contractInfo="contractInfo"
      :contract="certificateItemData.contract"
      :checkedNames="certificateItemData.inspectHistory"
      @onClickClose="onClickClosePopInspect"
    />
    <button v-if="!isFold" type="button" class="btn_bar_fold" @click="onClickFold">
      <span class="ico_account ico_bar_fold">접기</span>
    </button>
  </li>
</template>
<script>
import StatementPayment from "./contents/StatementPayment";
import StatementPartner from "./contents/StatementPartner";
import StatmentOtherMenuInfo from "./contents/StatmentOtherMenuInfo";
import StatementVoucher from "./contents/StatementVoucher";
import InfraVoucher from "./contents/InfraVoucher";
import StatementAsset from "./contents/StatementAsset";
import StatementPeriod from "./contents/StatementPeriod";
import StatementHoldingTax from "./contents/StatementHoldingTax";
import StatementSurtax from "./contents/StatementSurtax";
import PopInspect from "@/_approval/components/statement/view/popup/PopInspect";

export default {
  name: "StatementInfoLine",
  components: {
    StatementPayment,
    StatementPartner,
    StatmentOtherMenuInfo,
    StatementVoucher,
    InfraVoucher,
    StatementAsset,
    StatementPeriod,
    StatementHoldingTax,
    StatementSurtax,
    PopInspect,
  },
  props: {
    certificateItemData: Object,
    inspectHistory: Array,
    index: Number,
    isAddApprover: Boolean,
    isInfraStatement: Boolean,
    isCompleteStep: Boolean,
    docflag: String,
    apprType: String,
  },
  data() {
    return {
      isFold: true,
      isPopInspect: false,
      popInspectData: {},
      assetOpenIndex: null, // 자산전표항목 활성화 index
      assetVoucherIndexArray: [], // 자산전표항목 index 모음
    };
  },
  computed: {
    hasPeriod() {
      if (!this.certificateItemData.voucher || !this.certificateItemData.voucher.voucherItems)
        return false;

      const periodVoucher = this.certificateItemData.voucher.voucherItems
        .filter((voucher) => voucher.accountName)
        .filter((voucher) => voucher.accountName.indexOf("선급비용(기간인식)") > -1);

      if (periodVoucher.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    hasAsset() {
      this.setAssetAccount();
      if (this.assetVoucherIndexArray.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    selectedAssetAccountItem() {
      // 활성화된 전표데이터
      return this.certificateItemData.voucher.voucherItems[this.assetOpenIndex];
    },
  },
  methods: {
    // 자산전표세팅
    setAssetAccount(index) {
      if (
        this.isInfraStatement ||
        !this.certificateItemData.voucher ||
        !this.certificateItemData.voucher.voucherItems
      )
        return;

      const assetVoucherIndexArray = []; // 자산전표 index 모음
      this.certificateItemData.voucher.voucherItems.forEach((voucherItem, voucherItemIndex) => {
        let isAssetVoucher = voucherItem.infraAccountYn; // 자산항목중에 전표항목 찾기
        // 전표항목이 자산항목이 있을경우
        if (isAssetVoucher) {
          assetVoucherIndexArray.push(voucherItemIndex);
          // 전표항목이 자산항목이 아닐경우
        }
      });
      // 자산전표가 있으나 기존 활성화된 자산전표가 없을경우
      if (assetVoucherIndexArray.length != 0 && this.assetOpenIndex == null) {
        this.onUpdateAssetOpen(assetVoucherIndexArray[0]);
      }
      this.assetVoucherIndexArray = assetVoucherIndexArray;
    },
    //자산전표활성화index 업데이트
    onUpdateAssetOpen(index) {
      this.assetOpenIndex = index;
    },
    onClickFold() {
      this.isFold = !this.isFold;
    },
    onClickPopInspect(item) {
      this.popInspectData = item;
      this.isPopInspect = true;
    },
    onClickClosePopInspect() {
      this.isPopInspect = false;
      this.popInspectData = {};
    },
  },
};
</script>
<style scoped>
.head_fold {
  overflow: hidden;
  padding: 8px 24px 8px 24px;
  border-bottom: 1px solid #e1e1e1;
}
.box_choice {
  float: left;
}

/* 제목영역 */
.fold_subject {
  overflow: hidden;
}
.fold_subject .txt_subject {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 0;
  font-size: 12px;
  line-height: 18px;
  color: #222;
}

/* 우측 영역 버튼 */
.list_brief,
.list_brief dl,
.list_brief dt,
.list_brief dd {
  display: inline-block;
  vertical-align: top;
}
.list_brief {
  font-size: 11px;
  line-height: 32px;
  color: #555;
}
.list_brief dd + dt:after {
  float: left;
  width: 1px;
  height: 12px;
  margin: 10px 32px;
  background-color: #e1e1e1;
  content: "";
}
.list_brief .txt_price {
  display: inline-block;
  width: 141px;
  margin: -1px 4px 0 0;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  vertical-align: top;
}
.btn_fold {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 4px 0 4px 48px;
  outline: none;
}
.btn_fold .ico_fold {
  margin: 8px 5px;
}

.btn_bar_fold {
  display: block;
  width: 100%;
  height: 15px;
  padding: 4px 0 5px;
  border-top: 1px solid #ccc;
  background-color: #f5f5f5;
}

/* 열렸을때 */
.open .head_fold {
  background-color: #fbfbfb;
}
/* .open .txt_subject{color:#5551CE} */

/* 내용 영역 */
.body_fold {
  padding: 39px 24px 72px;
}
.body_fold /deep/ .tbl_comm {
  border-top: 1px solid #e1e1e1;
}
</style>
